.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  margin: 20px;
}

.home-text {
  word-break: break-word;
  margin: 0px 4px;
}

@media only screen and (max-width: 700px) {
  main {
    margin: 10px 0px;
  }
  #name,
  #token,
  #address,
  #wallet,
  #info {
    margin: 2px 0px;
  }
}
