#address {
  margin: 10px;
}

#wallet {
  margin: 10px;
}

#token {
  margin: 10px;
}

#info {
  margin: 20px;
}

.add-wallet {
  display: flex;
  justify-content: center;
}

#metamask-logo {
  height: 18px;
  width: 18px;
}

@media only screen and (max-width: 700px) {
  #name,
  #token,
  #address,
  #info {
    margin: 16px 0px;
    font-size: smaller;
  }
  #address {
    font-weight: 400;
    font-size: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .add-wallet {
    flex-direction: column;
  }
  #wallet {
    margin: 5px 0px 0px 0px;
  }
}
