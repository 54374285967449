#part-1,
#part-2 {
  font-size: 16px;
  margin: 20px;
}

#white-paper-btn {
  background-color: rgb(50, 132, 255);
  color: white;
  border: 1px solid rgb(50, 132, 255);
  border-radius: 4px;
  font-size: 18px;
  padding: 4px;
  text-decoration: none;
  transition: all 0.3s;
}

#white-paper-btn:hover {
  background-color: rgb(112, 169, 254);
}

#principles,
#one,
#two,
#three {
  font-size: 20px;
  margin: 5px;
}
