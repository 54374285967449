#nft-info {
  font-size: 18px;
  margin: 20px;
}

.nft {
  height: 120px;
  width: 120px;
  border-radius: 10px;
  margin: 5px;
}
