#contact-info {
  font-size: 18px;
  margin: 20px;
}

.logo {
  height: 20vmin;
}

#touch {
  margin: 40px 20px 10px 20px;
}

#my-name {
  margin: 10px 20px 20px 20px;
}

.social-btn {
  background-color: rgb(50, 132, 255);
  color: white;
  border: 1px solid rgb(50, 132, 255);
  border-radius: 4px;
  font-size: 16px;
  padding: 4px 6px;
  margin: 2px;
  text-decoration: none;
  transition: all 0.3s;
}

.social-btn:hover {
  background-color: rgb(112, 169, 254);
}
